<template>
  <div class="add-book">
    <BookForm isAdmin />
  </div>
</template>

<script>
import BookForm from "@/components/book-form/book-form";

export default {
  name: "add-book",
  components: {
    BookForm
  },
  data() {
    return {};
  }
};
</script>

<style></style>
